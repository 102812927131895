<template>
  <div class="mission">
    <van-sticky>
      <van-config-provider>
        <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
      </van-config-provider>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="mission-list">
        <van-list v-if="dataList.length >0">
          <div class="mission-block" v-for="item in dataList" :key="item.id">
            <van-image :src="item.imgUrl || '/images/task-banner.png'" />
            <div class="mission-title">{{item.name}}</div>
            <div class="mission-detail" v-for="detail in item.missionDetail" :key="detail.detailCode">
              <p class="mission-detail-title">{{detail.detailName}}</p>
              <p class="mission-detail-text">{{detail.explain}}</p>
              <p v-if="detail.status" class="mission-detail-status">Done</p>
              <p v-else>
                <span class="mission-detail-active">{{detail.value.split('/')[0]}}</span>
                <span class="mission-detail-divider">/</span>
                <span>{{detail.value.split('/')[1]}}</span>
              </p>
            </div>
            <van-button
              type="primary"
              round
              :disabled="!item.status"
              @click="handleReceiveMission(item)"
            >
              {{item.buttonName}}
            </van-button>
          </div>
        </van-list>
        <div v-else>
          <van-empty :description="$t('common.noData')"></van-empty>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import i18n from '@/assets/lang/index';
import { store } from '@/store';
import { queryMissionList, receiveMission } from "@/api";

export default {
  setup() {
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      refreshing,
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
    };
  },
  
  mounted() {
    this.fetchMissionList();
    // store.fetchQuickMenu();
  },

  methods: {
    fetchMissionList() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      queryMissionList().then(res => {
        this.dataList = res.data || [];
      });
    },
    handleReceiveMission(item){
      receiveMission({ id: item.id }).then(res => {
        this.fetchMissionList();
        // store.fetchQuickMenu();
      });
    },
    refreshData() {
      this.fetchMissionList();
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.mission {
  .mission-list {
    padding: 16px;
    .mission-block {
      padding: 12px;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 24px;
    }
    .mission-title {
      padding: 18px 0;
      font-size: 16px;
      font-weight: bold;
      color: #666;
    }
    .mission-detail {
      font-size: 16px;
      line-height: 20px;
      padding: 10px 0 16px;
      border-bottom: 1px solid var(--van-border-color);
      &:last-of-type {
        border-bottom: none;
      }
      .mission-detail-title {
        margin-bottom: 6px;
      }
      .mission-detail-text {
        font-size: 13px;
        color: #888;
        margin-bottom: 6px;
      }
      .mission-detail-status {
        font-weight: 500;
        color: blue;
      }
      span {
        font-weight: 500;
        &.mission-detail-active {
          color: red;
        }
        &.mission-detail-divider {
          margin: 0 6px;
        }
      }
    }
    .van-button {
      display: block;
      min-width: 200px;
      margin: 0 auto;
    }
  }
}
.theme1 .mission {
  .mission-list {
    padding: 24px 0;
    .mission-block {
      padding: 20px;
      border-radius: 0;
    }
    .van-button.van-button--disabled {
      color: #fff !important; 
      background: #a5a5a5 !important;
    }
  }
}
</style>
